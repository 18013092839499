import Layout from "../layout/Layout";
export default {
  path: "/bjsx",
  component: Layout,
  redirect: "/bjsx/info",
  children: [
    {
      path: "info",
      name: "bjsx-info",
      component: () => import("@/views/bjsx/index"),
      meta: { title: "办件事项", menuId: "ywlb" },
      children: [
        {
          path: "jspz",
          name: "info-jspz",
          component: () => import("@/views/flowinfo/jspz"),//路由懒加载 不用导入页面
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "lahz",
          name: "info-lahz",
          component: () => import("@/views/project/create"),//路由懒加载 不用导入页面
          meta: { title: "办件事项", menuId: "ywlb", isRegister: true },
        },
        {
          path: "jsydghxkz",
          name: "info-jsydghxkz",
          component: () => import("@/views/flowinfo/jsydghxkz"),//路由懒加载 不用导入页面
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "lsjsydghxkz",
          name: "info-lsjsydghxkz",
          component: () => import("@/views/flowinfo/lsjsydghxkz"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "tjhz",
          name: "info-tjhz",
          component: () => import("@/views/flowinfo/tjhz"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "file",
          name: "info-file",
          component: () => import("@/views/file/ShowFile"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "bzqksm",
          name: "info-bzqksm",
          component: () => import("@/views/flowinfo/bzqksm"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "sltzd",
          name: "info-sltzd",
          component: () => import("@/views/flowinfo/sltzd"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "ghtjtzs",
          name: "info-ghtjtzs",
          component: () => import("@/views/flowinfo/ghtjtzs"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "spb",
          name: "info-spb",
          component: () => import("@/views/Approval/Index"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "slsdhz",
          name: "info-slsdhz",
          component: () => import("@/views/flowinfo/sdhz"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "fzsdhz",
          name: "info-fzsdhz",
          component: () => import("@/views/flowinfo/sdhz"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "jsydghxm",
          name: "info-jsydghxm",
          component: () => import("@/views/flowinfo/jsydghxm"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "xzxm",
          name: "info-xzxm",
          component: () => import("@/views/flowinfo/xzxm"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "xkbg",
          name: "info-xkbg",
          component: () => import("@/views/flowinfo/xkbg"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "xkjd",
          name: "info-xkjd",
          component: () => import("@/views/flowinfo/xkjd"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "xzyjs",
          name: "info-xzyjs",
          component: () => import("@/views/flowinfo/xzyjs"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "jsgzghjgqr",
          name: "info-jsgzghjgqr",
          component: () => import("@/views/flowinfo/jsgzghjgqr"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "jsydghxkyj",
          name: "info-jsydghxkyj",
          component: () => import("@/views/flowinfo/jsydghxkyj"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "jsgcghxkyj",
          name: "info-jsgcghxkyj",
          component: () => import("@/views/flowinfo/jsgcghxkyj"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "jsydghxkyj",
          name: "info-jsydghxkyj",
          component: () => import("@/views/flowinfo/jsydghxkyj"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "lcxx",
          name: "info-lcxx",
          component: () => import("@/views/flowinfo/lcxx"),
          meta: { title: "办件事项", menuId: "ywlb" },
        },
        {
          path: "map",
          name: "info-yzt",
          component: () => import("@/views/flowinfo/map"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "bpxm",
          name: "info-bpxm",
          component: () => import("@/views/flowinfo/bpxm"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "jsgcghxm",
          name: "info-jsgcghxm",
          component: () => import("@/views/flowinfo/jsgcghxm"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "jsgcghxkz",
          name: "info-jsgcghxkz",
          component: () => import("@/views/flowinfo/jsgcghxkz"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "lsjsgcghxkz",
          name: "info-lsjsgcghxkz",
          component: () => import("@/views/flowinfo/lsjsgcghxkz"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "jsgcjgghhs",
          name: "info-jsgcjgghhs",
          component: () => import("@/views/flowinfo/jsgcjgghhs"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "jsxmgd",
          name: "info-jsxmgd",
          component: () => import("@/views/flowinfo/jsxmgd"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "xcjsghxkz",
          name: "info-xcjsghxkz",
          component: () => import("@/views/flowinfo/xcjsghxkz"),
          meta: { title: "办件事项",menuId: "ywlb" },
        },
        {
          path: "fwytjds",
          name: "info-fwytjds",
          component: () => import("@/views/flowinfo/fwytjds"),
          meta: { title: "慈溪市准予临时改变房屋用途决定书",menuId: "ywlb" },
        },
        {
          path: "sjfasc",
          name: "info-sjfasc",
          component: () => import("@/views/flowinfo/sjfasc"),
          meta: {title: "设计方案审查回复单", menuId: "ywlb"},
        },
        {
          path: "sylddjb",
          name: "info-sylddjb",
          component: () => import("@/views/flowinfo/sylddjb"),
          meta: {title: "办件事项", menuId: "ywlb", isRegister: true},
        },
        {
          path: "lssytdsqb",
          name: "info-lssytdsqb",
          component: () => import("@/views/flowinfo/lssytdsqb"),
          meta: {title: "办件事项", menuId: "ywlb", isRegister: true},
        },
        {
          path: "ysdwsqb",
          name: "info-ysdwsqb",
          component: () => import("@/views/flowinfo/ysdwsqb"),
          meta: {title: "办件事项", menuId: "ywlb", isRegister: true},
        },
        {
          path: "lssylddjb",
          name: "info-lssylddjb",
          component: () => import("@/views/flowinfo/lssylddjb"),
          meta: {title: "办件事项", menuId: "ywlb", isRegister: true},
        },
        {
          path: "zhyssqb",
          name: "info-zhyssqb",
          component: () => import("@/views/flowinfo/zhyssqb"),
          meta: {title: "办件事项", menuId: "ywlb", isRegister: true},
        },
        {
          path: "checknotify",
          name: "info-checknotify",
          component: () => import("@/views/flowinfo/checknotify"),
          meta: {title: "验收合格通知书", menuId: "ywlb"},
        },
        {
          path: "liveEstate",
          name: "info-liveEstate",
          component: () => import("@/views/flowinfo/liveEstate"),
          meta: {title: "居住用地规划", menuId: "ywlb"},
        },
        {
          path: "tradeEstate",
          name: "info-tradeEstate",
          component: () => import("@/views/flowinfo/tradeEstate"),
          meta: {title: "商业用地规划", menuId: "ywlb"},
        },
        {
          path: "richText",
          name: "info-richText",
          component: () => import("@/views/flowinfo/richText"),
          meta: {title: "工业用地规划", menuId: "ywlb"},
        },
        {
          path: "gzlxd",
          name: "info-gzlxd",
          component: () => import("@/views/flowinfo/gzlxd"),
          meta: {title: "办件事项", menuId: "ywlb"},
        },
        {
          path: "upload",
          name: "info-upload",
          component: () => import("@/views/flowinfo/upload"),
          meta: {title: "上传文件", menuId: "ywlb"},
        },
        {
          path: "ForestryCut",
          name: "info-ForestryCut",
          component: () => import("@/views/flowinfo/ForestryCut"),
          meta: {title: "办件事项", navnum: "14", isRegister: true},
        },
        {
          path: "SpecialMatters",
          name: "info-SpecialMatters",
          component: () => import("@/views/flowinfo/SpecialMatters"),
          meta: {title: "办件事项", navnum: "15", isRegister: true},
        },
        {
          path: "jsxmzhysspb",
          name: "info-jsxmzhysspb",
          component: () => import("@/views/flowinfo/jsxmzhysspb"),
          meta: {title: "办件事项", menuId: "ywlb"},
        },
        {
          path: "han",
          name: "info-han",
          component: () => import("@/views/flowinfo/han"),
          meta: {title: "办件事项", menuId: "ywlb"},
        },
      ]
    }
  ]
}

