<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import { ElConfigProvider } from 'element-plus'
import mgr from "@/services/security.js";
export default {
  name: "App",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    };
  },
  mounted() {
    window.mgr = mgr;
  },
};
</script>

<style>
.wrap-pop {
  padding: 0 !important;
}
</style>
