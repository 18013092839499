<template>
  <el-container class="app-wrapper">
    <!--头部区域-->
    <el-header class="header-one"> <NavBar /></el-header>

    <!--主体内容-->
    <el-container class="wrap-container">
      <!--左侧区域-->
      <el-aside :class="isCollapse?'c-aside':''"><LeftAside :isCollapse="isCollapse" /></el-aside>

      <!--右侧区域-->
      <el-container style="position: relative;">
        <!-- 标签区域 -->
        <el-header class="header-two">
          <AppHeader
            :isCollapse="isCollapse"
            :toggleCollapse="toggleCollapse"
          />
        </el-header>

        <!-- 内容区域 -->
        <el-main><AppMain /></el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import NavBar from "./components/NavBar";
import LeftAside from "./components/LeftAside";
import AppMain from "./components/AppMain";
import { defineComponent, ref, getCurrentInstance, onMounted } from "vue";
import mgr from "@/services/security.js";
import AppHeader from "./components/AppHeader.vue";
import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import warterMark from '@/components/warterMark.js'
export default defineComponent({
  name: "lay-out.vue",
  async beforeRouteEnter(to, from, next) {
    const user = await mgr.getUser();
    let $ajax = axios;
    let appConfig = window.appConfig
    function checkMenu(url) {
      return url.menuUrl == to.path || url.menuId == to.meta.menuId
    }
    function checkError(url) {
      return url.menuUrl == window.errUrl
    }
    function checkPath(url) {
      return url == to.path
    }
    if (user) {
      let response = await $ajax.get(appConfig.apiUrls.permissionApi +'/api/OutMenus/GetMenusByUser')
      if (response && response.data.isSuccess) {
        $ajax.menuUrl=response.data.data
        let menuList = response.data.data;
        let menuCheck = menuList.some(checkMenu);
        let pathList = ["/callback", "/Loading", "/", "", "/static/silent-renew.html", "/Account/index", "/Account/ChangePassword", "/Account/ChangePhoneNum", "/Account/NewsList"
        ,"/project/lahz", "/project/sylddjb", "/project/lssytdsqb", "/project/lssylddjb"
        ,"/project/zhyssqb", "/project/ysdwsqb", "/project/ForestryCut", "/project/SpecialMatters"];
        let pathCheck = pathList.some(checkPath);
        // 检测是否满足条件
        if (pathCheck || menuCheck) {
          next();
        }
        else if (menuList[0].menuUrl == window.errUrl) {
          ElMessageBox.confirm(
            '用户菜单地址配置出错',
            {
              confirmButtonText: '确认',
              showClose: false,
              showCancelButton: false,
              type: 'warning',
            }).then(() => {
              ElMessage({
                type: 'success',
                message: '已退出登录',
              })
              mgr.signoutRedirect();
            })
          next(false);
        } else if (menuList.find(checkError)) {
          ElMessage.warning({ message: '该菜单地址配置出错' })
          next(false);
        } else {
          next(menuList[0].menuUrl);
        }
      } else {
        ElMessage.warning({ message: '无法获取用户菜单' })
        mgr.signoutRedirect();
        next(false);
      }
    } else {
      mgr.signinRedirect(); //登录重定向
      next("/Loading");
    }
  },
  setup() {
    const windowWidth = ref(document.documentElement.clientWidth); //实时屏幕宽度
    const { proxy } = getCurrentInstance();
    const isCollapse = ref(false);

    const toggleCollapse = () => {
      proxy.isCollapse = !proxy.isCollapse;
    };
    const swCollapse = () => {
      if (proxy.windowWidth < 750) {
        proxy.isCollapse = true;
      }
    };
    onMounted(async() => {
      //初次进入监听窗口宽度折叠
      proxy.swCollapse();
      const user = await mgr.getUser();
      warterMark.set(user.profile.Username)
    });
    // window.onresize = () => {
    //   let that = proxy;
    //   return (() => {
    //     window.fullWidth = document.documentElement.clientWidth;
    //     that.windowWidth = window.fullWidth; // 宽
    //     // console.log("实时屏幕宽度：", that.windowWidth);
    //     if (that.windowWidth < 750) {
    //       that.isCollapse = true;
    //     } else {
    //       that.isCollapse = false;
    //     }
    //   })();
    // };
    return {
      isCollapse,
      toggleCollapse,
      windowWidth,
      swCollapse,
    };
  },

  components: {
    NavBar,
    LeftAside,
    AppMain,
    AppHeader,
  },
});
</script>

<style scoped>
.app-wrapper {
  height: 100%;
}
.el-aside {
  --el-aside-width: auto;
  transition: all 0.3s linear 0s;
  min-width: 200px;
}
.c-aside {
  width: 64px;
  min-width: 0;
}
.header-one {
  --el-header-padding: 0 20px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  width: 100%;
}
.header-two {
  padding: 0;
  height: auto;
  --el-header-height: 79px;
  border-bottom: 1px solid #e6e6e6;
}
.wrap-container {
  /* 高度必须有，里面的内容以这个高度隐藏 */
  height: 200px;
}
.el-main {
  height: 100%;
  padding: 0;
  background: #f2f2f2;
}
</style>
