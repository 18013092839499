<template>
  <div class="wrap-tags">
    <el-icon
      class="icon-fold"
      @click="toggleCollapse"
      :class="{ 'is-collapse': isCollapse }"
      ><Fold
    /></el-icon>
    <ScrollPane>
    <el-tag
      v-for="(tag, index) in routeTags"
      :key="tag"
      :closable="index == 0 ? false : tagBol"
      :disable-transitions="false"
      @close="handleClose(tag)"
      @click="switchTag(tag, index)"
      :class="isactive == index ? 'addclass' : ''"
    >
      {{ tag.meta.typeString?tag.meta.typeString:tag.meta.title}}
    </el-tag>
    </ScrollPane>
  </div>
</template>

<script>
import { Fold } from "@element-plus/icons";
import { newArr } from "../config/tagsm";
import ScrollPane from '../tagsview/ScrollPane.vue';
export default {
  data() {
    return {
      routeTags: [{ path: '', meta: { title: '' } }],//标签和路由
      tag: "",
      isactive: 0,//代表标签位置 和index对应
      delnav: "",
      tagBol: true,
      isChangeScroll: false,
      closeTag: {},
      isCloseTagLast: false,
    };
  },
  props: {
    toggleCollapse: {
      type: Function,
      required: true,
    },
    isCollapse: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Fold,
    ScrollPane,
  },
  watch: {
    $route(to) {
      let r={href:"",meta:{title:""}}
      r.href= to.href
      r.meta.title= to.meta.title
      if(to.query.instanceId){
        r.Id=to.query.instanceId
      }
      if(to.query.row){
        r.row=to.query.row
      }
      this.routeTags.push(r);//把每次路由去向push进数组
      let tagarr = [...this.routeTags];
      this.routeTags = newArr(tagarr);
      this.getsnav(r, this.routeTags);//for循环比较

      let suc = this.$ajax.menuUrl;
      function checkPath(url) {
        return url.href == r.href
      }
      function checkName(url) {
        if(url.children && url.children != null && url.children.length > 0){
          return url.children.find(checkName)
        }else{
          return url.menuUrl == r.href
        }
      }
      let path1 = suc.find(checkName)
      let path2 = this.routeTags.find(checkPath)
      if (path1 != undefined && path2 != undefined && path1.menuUrl == path2.href) {
        path2.meta.title = path1.menuName;
      }else if(path1 != undefined && path2 != undefined && path1.children && path1.children.length > 0){
        path2.meta.title = this.compareHref(path1.children,path2.href)
      }
      this.$router.routeTags=this.routeTags
      this.$store.dispatch('getUserInfo');
    },
    routeTags: {
      handler: function () {
        let eScroll=document.getElementsByClassName('scroll-container')[0]
        if (this.isChangeScroll) {
          eScroll.setAttribute("style","overflow-y: scroll;");
          this.isChangeScroll=!this.isChangeScroll
        }else{
          eScroll.setAttribute("style","overflow-y: auto;");
          this.isChangeScroll=!this.isChangeScroll
        }
      },
    },
    closeTag: {
      handler: function () {
        if(!this.isCloseTagLast){
          let eScroll=document.getElementsByClassName('scroll-container')[0]
          if (this.isChangeScroll) {
            eScroll.setAttribute("style","overflow-y: scroll;");
            this.isChangeScroll=!this.isChangeScroll
          }else{
            eScroll.setAttribute("style","overflow-y: auto;");
            this.isChangeScroll=!this.isChangeScroll
          }
        }
      }
    },
  },
  mounted() {
    let menuList = this.$ajax.menuUrl
    let r={href:"",meta:{title:""}}
    r.href= menuList[0].menuUrl
    r.meta.title= menuList[0].menuName
    this.routeTags[0]=r
    this.$router.handleClose = this.handleClose;
    if(this.$route.path != '/'){
      this.$router.push('/')
    }
  },
  methods: {
    compareHref(arr,href) {
      const len = arr.length;
      let name;
      if (len>0) {
        for (let i = 0; i < len; i++) {
          if(arr[i].children!=null&&arr[i].children.length>0){
            name = this.compareHref(arr[i].children,href);
            if (name) break;
          }else if (arr[i].menuUrl==href) {
            name = arr[i].menuName;
            break;
          }
        }
      }
      return name;
    },
    getsnav(to, item) {//对比当前路由是否已跳转过
      for (let r = 0; r < item.length; r++) {
        if (item[r].href == to.href) {
          this.isactive = r;//如果已经存在 就是第几个
        }
      }
    },
    // 根据标签切换导航
    switchTag(tag, index) {
      this.tag = tag;//此时的tag是前往的路由
      if(this.tag.row){
      this.$router.push(this.tag.href)
      }else{
        this.$router.push(this.tag.href);
      }
      this.isactive = index;//切换后 保持选中标签样式
    },
    handleClose(tag) {
        this.$router.closeTag = tag;
      window.sessionStorage.removeItem(tag.meta.Id)
        this.closeTag = tag;
        for (let s = 0; s < this.routeTags.length; s++) {
          if (this.routeTags[s].href == tag.href) {
            this.delnav = s;//关闭的tag所在位置
          }
        }
        let n;//当前的tag所在位置
        for (let s = 0; s < this.routeTags.length; s++) {
           if (this.routeTags[s].href === this.$route.href) {
             n = s;
             break;
           }else if(this.$route.query.instanceId && this.routeTags[s].meta.Id && this.routeTags[s].meta.Id === this.$route.query.instanceId){
             n = s;
             break;
           }
        }
        this.routeTags.splice(this.delnav, 1);
        let adel = this.routeTags.slice(-1);
        //关闭的tag是最后一个
        if(this.delnav === this.routeTags.length){
          //关闭的tag是最后一个 但是是当前tag
          if (this.delnav === n) {
            this.isCloseTagLast = true;
            this.$router.push(adel[0].href);
          //关闭的tag是最后一个 但不是当前tag
          }else{
            this.isCloseTagLast = false;
            return;
          }
        //关闭的tag不是最后一个
        }else{
           //关闭的tag不是最后一个 但是是当前tag
           if (this.delnav === n) {
             this.isCloseTagLast = true;
             this.$router.push(adel[0].href);
           //关闭的tag不是最后一个 但是在当前tag左边
           }else if(this.delnav < n){
             this.isCloseTagLast = false;
             this.isactive --;
           //关闭的tag不是最后一个 但是在当前tag右边
           }else{
             this.isCloseTagLast = false;
             return;
           }
        }
    }
  },

};
</script>

<style scoped>
.wrap-tags {
  width: 100%;
  display: inline-block;
  min-height: 33px;
}
.el-tag {
  margin-right: 5px;
  border-radius: 0 !important;
  --el-tag-background-color: rgb(255, 255, 255);
  --el-tag-border-color: #d8dce5;
  --el-tag-font-color: #3e6185;
}
.el-tag .el-icon-close {
  width: 30px;
}

.wrap-tags .el-tag:hover {
  cursor: pointer;
}
.wrap-tags .el-tag .el-icon-close {
  color: red;
}
.addclass {
  background-color: #42B983;
  border-radius: 0;
  color: #fff;
  --el-tag-font-color: #fff;
}
/* 表情文字前白色圆点 */
.addclass::before {
  content: "";
  background: #fff;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: relative;
  margin-right: 2px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.icon-fold {
  font-size: 25px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  /* top: 7px; */
  transition: 0.2s;
  /* vertical-align: middle; */
}
.is-collapse {
  transform: rotate(-180deg);
}
/*滚动条样式*/
.scroll-container::-webkit-scrollbar { /*滚动条整体样式*/
  width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.scroll-container::-webkit-scrollbar-thumb { /*滚动条里面小方块*/
  background: rgba(0,0,0,0);
}
.scroll-container::-webkit-scrollbar-track { /*滚动条里面轨道*/
  background: rgba(0,0,0,0);
}
</style>
