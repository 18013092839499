<template>
 <el-submenu :index="menuItem.menuId" v-if="menuItem.children != null && menuItem.children.length > 0" class="wrap-sub">
    <template #title>
      <i :class="menuItem.menuIcon"></i>
      <span>{{ menuItem.menuName }}</span>
    </template>
    <template v-for="child in menuItem.children" :key="child.path">
      <el-submenu
        :index="child.menuId"
        class="else-color"
        v-if="child.children != null && child.children.length > 0"
      >
        <template #title>
          <i :class="child.menuIcon"></i>
          <span>{{ child.menuName }}</span>
        </template>
       <el-menu-item
          v-for="sj in child.children"
          :key="sj.path"
          :index="sj.menuId"
          @click="navLink(sj)"
        >
          <i :class="sj.menuIcon"></i>
          <template #title class="wrapt">{{ sj.menuName }}</template>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :index="child.menuId" @click="navLink(child)">
        <i :class="child.menuIcon"></i>
        <template #title class="wrapt">{{ child.menuName }}</template>
      </el-menu-item>
    </template>
  </el-submenu>
  <el-menu-item :index="JSON.stringify(menuItem.indexNo)" @click="navLink(menuItem)" v-else>
    <i :class="menuItem.menuIcon"></i>
    <template #title class="wrapt">{{ menuItem.menuName }}</template>
  </el-menu-item>
</template>
<script>
export default {
  name:'Submenu',
  props: {
    menuList: {
      type: Object,
    },
    menuItem: {
      type: Object,
    },
  },
  methods: {
    navLink(activepath) {
      this.$router.push(activepath.menuUrl);
    }
  },
  mounted() {
  },
}
</script>

<style scoped>
.wrap-sub > ul>li{
   background-color: #304156;
}
.wrap-sub :deep(.el-menu-item) {
    background-color: #1f2d3d!important;
}
.wrap-sub :deep(.el-menu-item):hover {
    background-color: #001528!important;
}
</style>
<style>
.el-submenu .el-popper .el-menu--vertical .el-menu--popup {
  overflow-y: auto;
  max-height: 100vh;
}
.el-popper .el-menu--vertical .el-menu--popup::-webkit-scrollbar {
  width: 6px;
}
.el-popper .el-menu--vertical .el-menu--popup::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #99a9bf;
}
.el-popper .el-menu--vertical .el-menu--popup::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #d3dce6;
  border-radius: 5px;
}
.else-color .el-submenu__title{
  background-color: rgb(31 45 61) !important;
}
.else-color .el-submenu__title:hover{
  background-color: #001528 !important;
}
</style>