<template>
<el-scrollbar>
  <el-menu
    active-text-color="#409EFF"
    background-color="#304156"
    class="el-menu-vertical-demo"
    :default-active="activeId"
    :collapse="isCollapse"
    text-color="#fff"
    @open="handleOpen"
    @close="handleClose"
  >
     <el-input
      v-model="searchValue"
      placeholder="搜索菜单..."
      class="input-with-select"
      id="input-color"
      v-show="!isCollapse"
      @blur="changeColor"
      @focus="recoverColor"
      @keyup.enter="menuSearch"
    >
        <template #suffix>
          <el-icon class="el-icon-search" @click="menuSearch">
          </el-icon>
        </template>
    </el-input>
    <Submenu v-for="route in menuList" :key="route.path" :menuItem="route" />
    <a :href="$appConfig.mapUrl+'?p=0'" target="_blank" class="wrap-map">
        <el-menu-item index="99">
          <i class="el-icon-map-location"></i>
          <template #title>一张图</template>
        </el-menu-item>
    </a>
  </el-menu>
  </el-scrollbar>
  <!-- <p>测试{{menuList}}</p> -->
</template>

<script>
// import menuConfig from "../config/menuConfig";
import Submenu from '../tagsview/Submenu.vue';
export default {
  components: { Submenu },
  props: {
    isCollapse: {
      type: Boolean,
    },
  },
  watch: {
    $route(to) {
      for(let i=0; i<this.$ajax.menuUrl.length; i++){
         if(this.$ajax.menuUrl[i].menuUrl != null &&this.$ajax.menuUrl[i].menuUrl === to.href){
          this.activeId = JSON.stringify(this.$ajax.menuUrl[i].indexNo);
         }else if(this.$ajax.menuUrl[i].children.length > 0){
          this.activeId =this.checkHref(this.$ajax.menuUrl[i].children,to.href)
         }
      }
    },
  },
  data() {
    return {
      menuList: [],
      activeId: this.$route.meta.navnum,
      searchValue: '',
    }
  },
  mounted() {
    this.activeId = this.$route.meta.navnum;
    this.menuList = this.$ajax.menuUrl;
    // this.menuList= menuConfig;
  },
  methods: {
    changeColor(){
      let c=document.getElementById('input-color');
      c.style.color='#C0C4CC'
    },
    recoverColor(){
      let c=document.getElementById('input-color');
      c.style.color='#606266'
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    //点击菜单进行路由跳转
    navLink(activepath) {
      // console.log('路由', activepath);
      this.$router.push(activepath.menuUrl);
    },
    menuSearch(){
      let menuData = this.$ajax.menuUrl;
      let s= this.compareValue(menuData)
      if(s!=undefined){
        this.$router.push(s.menuUrl)
      }
    },
    compareValue(arr) {
      const len = arr.length;
      let name;
      if (len>0) {
        for (let i = 0; i < len; i++) {
          if(arr[i].children!=null&&arr[i].children.length>0){
            name = this.compareValue(arr[i].children);
            if (name) break;
          }else if (arr[i].menuName.indexOf(this.searchValue)>-1) {
            name = arr[i];
            break;
          }
        }
      }
      return name;
    },
     checkHref(arr,href) {
      const len = arr.length;
      let name;
      if (len>0) {
        for (let i = 0; i < len; i++) {
          if(arr[i].children!=null&&arr[i].children.length>0){
            name = this.checkHref(arr[i].children,href);
            if (name) break;
          }else if (arr[i].menuUrl === href) {
            name = arr[i].menuId;
            break;
          }
        }
      }
      return name;
    },
  },
}
</script>
<style scoped>
:deep(.el-scrollbar__view){
  height: 100%;
}
:deep(.el-scrollbar__bar.is-vertical > div):hover{
  background-color: rgb(255, 255, 255);
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-width: 200px;
  height: 100%;
}
.el-menu-item i {
  /* margin-right: 12px; */
}
.el-menu--collapse {
  height: 100%;
}
:deep(.el-input){
  --el-input-border: 0px;
  width: calc(100% - 20px);
  padding: 10px;
}
:deep(.el-input__inner){
  background-color: #384c64;
}
:deep(.el-input__inner):focus{
  background-color: rgb(255, 255, 255);
}
:deep(.el-input__suffix .el-icon){
  font-size: 16px;
  font-weight: bold;
  padding: 10px 16px 10px 10px;
  margin-top: 9px;
  cursor: pointer;
}
</style>
