import { createStore } from 'vuex'
import axios from "@/api/axios.js";
export default createStore({
  state: {
    errorLog: [],//存储日志
    userInfo: {},//用户信息
    AJH: '',//案卷号
    XMDM: '',//项目代码
    currentStepName: '',//当前阶段
    newsCount:0,//消息数量
    formData: {}, //数据,
    routeTags: [''] //缓存
  },
  mutations: {
    addAxiosError(state, item) {
      state.errorLog.push(item)
      console.log('打印错误日志：', state);
    },
    getUserInfo(state, item) {
      state.userInfo = item[0];
      state.newsCount = item[1];
    },
    getFormData(state, item) {
      state.formData = item;
    },
    getRouteTags(state, item) {
      state.routeTags = JSON.parse(JSON.stringify(item))
    },
  },
  actions: {
    setLogs(context, value) { //使用方法：$store.dispatch('setLogs',value);
      context.commit('addAxiosError', value);
    },
    submitErrors(context) {
      return new Promise((reslove, reject) => {
        axios.get('地址').then(res => {
          context.commit('addAxiosError', res)
          reslove(res)
        }).catch(res => { reject(res) })
      })
    },
    async getUserInfo (context) {
      await axios
        .get(window.appConfig.apiUrls.userApi + "/api/User/GetUserInfo")
        .then(async function (response) {
          if (response.data.IsSuccess) {
            let res = response.data.Data
            let res2 = await axios({
                url: window.appConfig.apiUrls.messageApi +
                '/api/Message/GetUserMessageCount?userId='+
                res.UserId,
                method: 'get',
                headers: {
                  'Cache-control': 'no-cache, no-store, must-revalidate',
                  'Pragma': 'no-cache',
                  'Expires': 0
                }
              })
            let arr=[ res, res2.data.Data ]
            context.commit('getUserInfo', arr)
          } else {
            console.log('获取用户信息失败：',response.data.errorMessage);
          }
        });
    },
  },
  getters: {
  }
})
