// import { createRouter, createMemoryHistory } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import Layout from "../layout/Layout";
import Login from "@/views/login/index";
import Callback from "@/views/Callback";
import Loading from "@/views/Loading";
import BJSX from "./bjsx";

const routes = [
  // 这里的navnum对应menuConfig.json中的id
  {
    path: "/callback",
    name: "callback",
    component: Callback,
  },
  {
    path: "/Loading",
    name: "Loading",
    component: Loading,
  },
  { path: "/login", component: Login }, // { path: "", redirect: "/login" },
  {
    path: "/",
    name: "LayOut",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/home/index"),
        meta: { title: "项目登记", navnum: "1" },
      },
    ],
  },
  {
    path: "/buslist",
    component: Layout,
    redirect: "/buslist/index",
    children: [
      {
        path: "index",
        name: "buslist",
        component: () => import("@/views/buslist/index"),
        meta: { title: "办件中心", navnum: "2", menuId: "ywlb" },
      },
      {
        path: "flowinfo",
        name: "flowinfo",
        component: () => import("@/views/flowinfo/index"),
        meta: { title: "流程信息", menuId: "ywlb" },
        children: [
          {
            path: "jspz",
            name: "flowinfo-jspz",
            component: () => import("@/views/flowinfo/jspz"),//路由懒加载 不用导入页面
            meta: { title: "流程信息", menuId: "ywlb" },//title: "流程信息", menuId: "ywlb"这两个最好不要变  如果是和他同级的路由 要有这个 是我判断权限的
          },
          {
            path: "lahz",
            name: "flowinfo-lahz",
            component: () => import("@/views/project/create"),//路由懒加载 不用导入页面
            meta: { title: "流程信息", menuId: "ywlb", isRegister: true },//title: "流程信息", menuId: "ywlb"这两个最好不要变  如果是和他同级的路由 要有这个 是我判断权限的
          },
          {
            path: "jsydghxkz",
            name: "flowinfo-jsydghxkz",
            component: () => import("@/views/flowinfo/jsydghxkz"),//路由懒加载 不用导入页面
            meta: { title: "流程信息", menuId: "ywlb" },//title: "流程信息", menuId: "ywlb"这两个最好不要变  如果是和他同级的路由 要有这个 是我判断权限的
          },
          {
            path: "lsjsydghxkz",
            name: "flowinfo-lsjsydghxkz",
            component: () => import("@/views/flowinfo/lsjsydghxkz"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "tjhz",
            name: "flowinfo-tjhz",
            component: () => import("@/views/flowinfo/tjhz"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "file",
            name: "flowinfo-file",
            component: () => import("@/views/file/ShowFile"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "bzqksm",
            name: "flowinfo-bzqksm",
            component: () => import("@/views/flowinfo/bzqksm"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "sltzd",
            name: "flowinfo-sltzd",
            component: () => import("@/views/flowinfo/sltzd"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "ghtjtzs",
            name: "flowinfo-ghtjtzs",
            component: () => import("@/views/flowinfo/ghtjtzs"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "spb",
            name: "flowinfo-spb",
            component: () => import("@/views/Approval/Index"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "slsdhz",
            name: "flowinfo-slsdhz",
            component: () => import("@/views/flowinfo/sdhz"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "fzsdhz",
            name: "flowinfo-fzsdhz",
            component: () => import("@/views/flowinfo/sdhz"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "jsydghxm",
            name: "flowinfo-jsydghxm",
            component: () => import("@/views/flowinfo/jsydghxm"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "xzxm",
            name: "flowinfo-xzxm",
            component: () => import("@/views/flowinfo/xzxm"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "xkbg",
            name: "flowinfo-xkbg",
            component: () => import("@/views/flowinfo/xkbg"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "xkjd",
            name: "flowinfo-xkjd",
            component: () => import("@/views/flowinfo/xkjd"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "xzyjs",
            name: "flowinfo-xzyjs",
            component: () => import("@/views/flowinfo/xzyjs"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "jsgzghjgqr",
            name: "flowinfo-jsgzghjgqr",
            component: () => import("@/views/flowinfo/jsgzghjgqr"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "jsydghxkyj",
            name: "flowinfo-jsydghxkyj",
            component: () => import("@/views/flowinfo/jsydghxkyj"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "jsgcghxkyj",
            name: "flowinfo-jsgcghxkyj",
            component: () => import("@/views/flowinfo/jsgcghxkyj"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "jsydghxkyj",
            name: "flowinfo-jsydghxkyj",
            component: () => import("@/views/flowinfo/jsydghxkyj"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "lcxx",
            name: "flowinfo-lcxx",
            component: () => import("@/views/flowinfo/lcxx"),
            meta: { title: "流程信息", menuId: "ywlb" },
          },
          {
            path: "map",
            name: "flowinfo-yzt",
            component: () => import("@/views/flowinfo/map"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "jsgcghxm",
            name: "flowinfo-jsgcghxm",
            component: () => import("@/views/flowinfo/jsgcghxm"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "jsgcghxkz",
            name: "flowinfo-jsgcghxkz",
            component: () => import("@/views/flowinfo/jsgcghxkz"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "lsjsgcghxkz",
            name: "flowinfo-lsjsgcghxkz",
            component: () => import("@/views/flowinfo/lsjsgcghxkz"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "jsgcjgghhs",
            name: "flowinfo-jsgcjgghhs",
            component: () => import("@/views/flowinfo/jsgcjgghhs"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "bpxm",
            name: "flowinfo-bpxm",
            component: () => import("@/views/flowinfo/bpxm"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "jsxmgd",
            name: "flowinfo-jsxmgd",
            component: () => import("@/views/flowinfo/jsxmgd"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "xcjsghxkz",
            name: "flowinfo-xcjsghxkz",
            component: () => import("@/views/flowinfo/xcjsghxkz"),
            meta: { title: "流程信息",menuId: "ywlb" },
          },
          {
            path: "fwytjds",
            name: "flowinfo-fwytjds",
            component: () => import("@/views/flowinfo/fwytjds"),
            meta: { title: "慈溪市准予临时改变房屋用途决定书",menuId: "ywlb" },
          },
          {
            path: "sjfasc",
            name: "flowinfo-sjfasc",
            component: () => import("@/views/flowinfo/sjfasc"),
            meta: {title: "设计方案审查回复单", menuId: "ywlb"},
          },
          {
            path: "sylddjb",
            name: "flowinfo-sylddjb",
            component: () => import("@/views/flowinfo/sylddjb"),
            meta: {title: "流程信息", menuId: "ywlb", isRegister: true},
          },
          {
            path: "lssytdsqb",
            name: "flowinfo-lssytdsqb",
            component: () => import("@/views/flowinfo/lssytdsqb"),
            meta: {title: "流程信息", menuId: "ywlb", isRegister: true},
          },
          {
            path: "ysdwsqb",
            name: "flowinfo-ysdwsqb",
            component: () => import("@/views/flowinfo/ysdwsqb"),
            meta: {title: "流程信息", menuId: "ywlb", isRegister: true},
          },
          {
            path: "lssylddjb",
            name: "flowinfo-lssylddjb",
            component: () => import("@/views/flowinfo/lssylddjb"),
            meta: {title: "流程信息", menuId: "ywlb", isRegister: true},
          },
          {
            path: "zhyssqb",
            name: "flowinfo-zhyssqb",
            component: () => import("@/views/flowinfo/zhyssqb"),
            meta: {title: "流程信息", menuId: "ywlb", isRegister: true},
          },
          {
            path: "checknotify",
            name: "checknotify",
            component: () => import("@/views/flowinfo/checknotify"),
            meta: {title: "验收合格通知书", menuId: "ywlb"},
          },
          {
            path: "liveEstate",
            name: "liveEstate",
            component: () => import("@/views/flowinfo/liveEstate"),
            meta: {title: "居住用地规划", menuId: "ywlb"},
          },
          {
            path: "tradeEstate",
            name: "tradeEstate",
            component: () => import("@/views/flowinfo/tradeEstate"),
            meta: {title: "商业用地规划", menuId: "ywlb"},
          },
          {
            path: "richText",
            name: "richText",
            component: () => import("@/views/flowinfo/richText"),
            meta: {title: "工业用地规划", menuId: "ywlb"},
          },
          {
            path: "gzlxd",
            name: "flowinfo-gzlxd",
            component: () => import("@/views/flowinfo/gzlxd"),
            meta: {title: "流程信息", menuId: "ywlb"},
          },
          {
            path: "upload",
            name: "upload",
            component: () => import("@/views/flowinfo/upload"),
            meta: {title: "上传文件", menuId: "ywlb"},
          },
          {
            path: "ForestryCut",
            name: "flowinfo-ForestryCut",
            component: () => import("@/views/flowinfo/ForestryCut"),
            meta: {title: "林业采伐", navnum: "14", isRegister: true},
          },
          {
            path: "SpecialMatters",
            name: "flowinfo-SpecialMatters",
            component: () => import("@/views/flowinfo/SpecialMatters"),
            meta: {title: "特殊事项", navnum: "15", isRegister: true},
          },
          {
            path: "jsxmzhysspb",
            name: "jsxmzhysspb",
            component: () => import("@/views/flowinfo/jsxmzhysspb"),
            meta: {title: "建设项目综合验收审批表", menuId: "ywlb"},
          },
          {
            path: "han",
            name: "han",
            component: () => import("@/views/flowinfo/han"),
            meta: {title: "函", menuId: "ywlb"},
          },
        ]
      },
    ],
  },
  BJSX,
  {
    path: "/parcellist",
    component: Layout,
    redirect: "/parcellist/index",
    children: [
      {
        path: "index",
        name: "parcellist",
        component: () => import("@/views/parcellist/index"),
        meta: { title: "地块列表", navnum: "3", menuId: "dklb" },
      },
      {
        path: "edit",
        name: "edit",
        component: () => import("@/views/parcellist/edit"),
        meta: { title: "修改地块", menuId: "dklb" },
      },
    ],
  },
  {
    path: "/prolist",
    component: Layout,
    redirect: "/prolist/index",
    children: [
      {
        path: "index",
        name: "prolist",
        component: () => import("@/views/prolist/index"),
        meta: { title: "项目列表", navnum: "4" },
      },
    ],
  },
  {
    path: "/project",
    component: Layout,
    redirect: "/project/index",
    children: [
      {
        path: "index",
        name: "project",
        component: () => import("@/views/project/index"),
        meta: { title: "项目查询", navnum: "5" },
      },
    ],
  },
  {
    path: "/info",
    component: Layout,
    redirect: "/info/index",
    children: [
      {
        path: "index",
        name: "info",
        component: () => import("@/views/info/index"),
        meta: { title: "公开信息", navnum: "6", menuId: "gkxx" },
      },
      {
        path: "details",
        name: "details",
        component: () => import("@/views/info/infoDetails"),
        meta: { title: "关于", menuId: "gkxx" },
      },
    ],
  },

  {
    path: "/LandTransferInfo",
    component: Layout,
    redirect: "/LandTransferInfo/List",
    children: [
      {
        path: "List",
        name: "List",
        component: () => import("@/views/LandTransferInfo/List"),
        meta: { title: "出让地块列表", navnum: "7" },
      },
    ],
  },
  {
    path: "/Company",
    component: Layout,
    redirect: "/Company/Index",
    children: [
      {
        path: "Index",
        name: "Company",
        component: () => import("@/views/Company/Index"),
        meta: { title: "测绘单位管理", navnum: "8" },
      },
    ],
  },
  {
    path: "/create",
    component: Layout,
    redirect: "/project/create",
    children: [
      {
        path: "/project/create",
        name: "navigation",
        component: () => import("@/views/registration/navigation"),
        meta: { title: "办件导航", navnum: "9" },
      },
      {
        path: "/project/lahz",
        name: "lahz",
        component: () => import("@/views/project/create"),
        meta: { title: "立案回执", navnum: "10" },
      },
      {
        path: "/project/sylddjb",
        name: "sylddjb",
        component: () => import("@/views/flowinfo/sylddjb"),
        meta: {title: "使用林地登记", navnum: "11"},
      },
      {
        path: "/project/zhyssqb",
        name: "zhyssqb",
        component: () => import("@/views/flowinfo/zhyssqb"),
        meta: {title: "综合验收申请表"},
      },
      {
        path: "/project/lssylddjb",
        name: "lssylddjb",
        component: () => import("@/views/flowinfo/lssylddjb"),
        meta: {title: "使用土地登记"},
      },
      {
        path: "/project/lssytdsqb",
        name: "lssytdsqb",
        component: () => import("@/views/flowinfo/lssytdsqb"),
        meta: {title: "临时使用土地申请", navnum: "12"},
      },
      {
        path: "/project/ysdwsqb",
        name: "ysdwsqb",
        component: () => import("@/views/flowinfo/ysdwsqb"),
        meta: {title: "野生动物申请", navnum: "13"},
      },
      {
        path: "/project/ForestryCut",
        name: "ForestryCut",
        component: () => import("@/views/flowinfo/ForestryCut"),
        meta: {title: "林业采伐", navnum: "14"},
      },
      {
        path: "/project/SpecialMatters",
        name: "SpecialMatters",
        component: () => import("@/views/flowinfo/SpecialMatters"),
        meta: {title: "特殊事项", navnum: "15"},
      },
    ],
  },
  {
    path: "/Approval",
    component: Layout,
    redirect: "/Approval/Index",
    children: [
      {
        path: "Index",
        name: "Approval",
        component: () => import("@/views/Approval/Index"),
        meta: { title: "审批表", navnum: "10" },
      },
    ],
  },
  {
    path: "/jspz",
    component: Layout,
    redirect: "/flowinfo/jspz",
    children: [
      {
        path: "/flowinfo/jspz",
        name: "jspz",
        component: () => import("@/views/flowinfo/jspz"),
        meta: { title: "接收凭证", navnum: "15" },
      },
    ],
  },
  {
    path: "/bzqksm",
    component: Layout,
    redirect: "/flowinfo/bzqksm",
    children: [
      {
        path: "/flowinfo/bzqksm",
        name: "bzqksm",
        component: () => import("@/views/flowinfo/bzqksm"),
        meta: { title: "备注情况说明", navnum: "16" },
      },
    ],
  },
  {
    path: "/Account",
    component: Layout,
    redirect: "/Account/index",
    children: [
      {
        path: "Index",
        name: "Account",
        component: () => import("@/views/Account/Index"),
        meta: { title: "账户管理", menuId: "zhgl" },
      },
      {
        path: "ChangePassword",
        name: "ChangePassword",
        component: () => import("@/views/Account/ChangePassword"),
        meta: { title: "账户管理", menuId: "zhgl" },
      },
      {
        path: "ChangePhoneNum",
        name: "ChangePhoneNum",
        component: () => import("@/views/Account/ChangePhoneNum"),
        meta: { title: "账户管理", menuId: "zhgl" },
      },
      {
        path: "NewsList",
        name: "NewsList",
        component: () => import("@/views/Account/NewsList"),
        meta: { title: "消息列表", menuId: "zhgl" },
      },
    ],
  },
  {
    path: "/tjhz",
    component: Layout,
    redirect: "/tjhz/index",
    children: [
      {
        path: "index",
        name: "tjhz",
        component: () => import("@/views/flowinfo/tjhz"),
        meta: { title: "退件回执", navnum: "11" },
      }
    ]
  },
  {
    path: "/sltzd",
    component: Layout,
    children: [
      {
        path: "/sltzd/index",
        name: "sltzd",
        component: () => import("@/views/flowinfo/sltzd"),
        meta: { title: "受理通知单" },
      }
    ]
  },
  {
    path: "/sdhz",
    component: Layout,
    children: [
      {
        path: "/sdhz",
        name: "sdhz",
        component: () => import("@/views/flowinfo/sdhz"),
        meta: { title: "送达回执" },
      }
    ]
  },
  {
    path: "/ghtjtzs",
    component: Layout,
    children: [
      {
        path: "/ghtjtzs",
        name: "ghtjtzs",
        component: () => import("@/views/flowinfo/ghtjtzs"),
        meta: { title: "规划条件通知书" },
      }
    ]
  },
  {
    path: "/jsydghxkz",
    component: Layout,
    redirect: "/jsydghxkz/index",
    children: [
      {
        path: "index",
        name: "jsydghxkz",
        component: () => import("@/views/flowinfo/jsydghxkz"),
        meta: { title: "建设用地规划许可证", navnum: "22" },
      }
    ]
  },
  {
    path: "/jsydghxkyj",
    component: Layout,
    children: [
      {
        path: "/jsydghxkyj",
        name: "jsydghxkyj",
        component: () => import("@/views/flowinfo/jsydghxkyj"),
        meta: { title: "建设用地许可证申请表规划局意见" },
      }
    ]
  },
  {
    path: "/jsgcghxkz",
    component: Layout,
    children: [
      {
        path: "/jsgcghxkz",
        name: "jsgcghxkz",
        component: () => import("@/views/flowinfo/jsgcghxkz"),
        meta: { title: "建设工程规划许可证" },
      }
    ]
  },
  {
    path: "/jsgcghxkyj",
    component: Layout,
    children: [
      {
        path: "/jsgcghxkyj",
        name: "jsgcghxkyj",
        component: () => import("@/views/flowinfo/jsgcghxkyj"),
        meta: { title: "建设工程规划许可证申请表本局意见" },
      }
    ]
  },
  {
    path: "/xcjsghxkz",
    component: Layout,
    children: [
      {
        path: "/xcjsghxkz",
        name: "xcjsghxkz",
        component: () => import("@/views/flowinfo/xcjsghxkz"),
        meta: { title: "乡村建设规划许可证" },
      }
    ]
  },
  {
    path: "/jsydghxm",
    component: Layout,
    redirect: "/jsydghxm/index",
    children: [
      {
        path: "index",
        name: "jsydghxm",
        component: () => import("@/views/flowinfo/jsydghxm"),
        meta: { title: "建设用地规划项目", navnum: "400" },
      }
    ]
  },
  {
    path: "/xzxm",
    component: Layout,
    redirect: "/xzxm/index",
    children: [
      {
        path: "index",
        name: "xzxm",
        component: () => import("@/views/flowinfo/xzxm"),
        meta: { title: "选址项目", navnum: "401" },
      }
    ]
  },
  {
    path: "/xkbg",
    component: Layout,
    redirect: "/flowinfo/xkbg",
    children: [
      {
        path: "/flowinfo/xkbg",
        name: "xkbg",
        component: () => import("@/views/flowinfo/xkbg"),
        meta: { title: "许可变更", navnum: "26" },
      },
    ],
  },
  {
    path: "/xkjd",
    component: Layout,
    redirect: "/flowinfo/xkjd",
    children: [
      {
        path: "/flowinfo/xkjd",
        name: "xkjd",
        component: () => import("@/views/flowinfo/xkjd"),
        meta: { title: "许可决定", navnum: "27" },
      },
    ],
  },
  {
    path: "/xzyjs",
    component: Layout,
    redirect: "/flowinfo/xzyjs",
    children: [
      {
        path: "/flowinfo/xzyjs",
        name: "xzyjs",
        component: () => import("@/views/flowinfo/xzyjs"),
        meta: { title: "建设项目预审与选址意见书", navnum: "28" },
      },
    ],
  },
  {
    path: "/jsgzghjgqr",
    component: Layout,
    redirect: "/flowinfo/jsgzghjgqr",
    children: [
      {
        path: "/flowinfo/jsgzghjgqr",
        name: "jsgzghjgqr",
        component: () => import("@/views/flowinfo/jsgzghjgqr"),
        meta: { title: "建设工程规划竣工确认书", navnum: "29" },
      },
    ],
  },
  {
    path: "/search",
    component: Layout,
    children: [
      {
        path: "/search",
        name: "search",
        component: () => import("@/views/search/Index"),
        meta: { title: "综合查询" , navnum: "31"},
      }
    ]
  },
  {
    path: "/lcxx",
    component: Layout,
    redirect: "/flowinfo/lcxx",
    children: [
      {
        path: "/flowinfo/lcxx",
        name: "lcxx",
        component: () => import("@/views/flowinfo/lcxx"),
        meta: { title: "流程信息" },
      },
    ],
  },
  {
    path: "/jsxm",
    component: Layout,
    children: [
      {
        path: "/jsxm",
        name: "jsxm",
        component: () => import("@/views/jsxm/Index"),
        meta: { title: "建设项目",menuId: "jsxm", navnum: "30" },
      },
      {
        path: "/smzq",
        name: "smzq",
        component: () => import("@/views/jsxm/smzq"),
        meta: { title: "生命周期",menuId: "jsxm" },
      },
    ]
  },
  {
    path: "/bpxm",
    component: Layout,
    children: [
      {
        path: "/bpxm",
        name: "bpxm",
        component: () => import("@/views/flowinfo/bpxm"),
        meta: { title: "报批项目" },
      }
    ]
  },
  {
    path: "/nzyxm",
    component: Layout,
    children: [
      {
        path: "/nzyxm",
        name: "nzyxm",
        component: () => import("@/views/flowinfo/nzyxm"),
        meta: { title: "农转用项目", menuId: "ywlb" },
      }
    ]
  },
  {
    path: "/jsxmgd",
    component: Layout,
    redirect: "/flowinfo/jsxmgd",
    children: [
      {
        path: "/flowinfo/jsxmgd",
        name: "jsxmgd",
        component: () => import("@/views/flowinfo/jsxmgd"),
        meta: { title: "建设项目供地" },
      },
    ],
  },
  {
    path: "/cancel",
    component: Layout,
    children: [
      {
        path: "/cancel",
        name: "cancel",
        component: () => import("@/views/cancel/list"),
        meta: { title: "作废项目管理", navnum: "40" },
      },
    ],
  },
  {
    path: "/recall",
    component: Layout,
    children: [
      {
        path: "/recall",
        name: "recall",
        component: () => import("@/views/recall/list"),
        meta: { title: "项目撤回", navnum: "50" },
      },
    ],
  },
];

const router = createRouter({
  // history: createMemoryHistory(), //隐藏路由
  history: createWebHistory(),

  routes, // `routes: routes` 的缩写
});
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    window.errUrl = to.href;
    next('/');
    return;
  }
  next();
})
export default router;
