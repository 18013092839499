import { createApp } from "vue";
import App from "./App.vue";
import installElementPlus from "./plugins/element";
import router from "./router";
import "./styles/index.css";
import axios from "@/api/axios.js";
import $store from "./store";
// 导入模拟数据
import "../mock/index";

const app = createApp(App);
installElementPlus(app);
app.config.globalProperties.$ajax = axios;
app.config.globalProperties.$appConfig = window.appConfig;
app.use(router);
app.use($store);
app.mount("#app");
