<template>
    <div class="login">
    <div class="login-form">
      <el-form
        :model="form"
        ref="loginForm"
        class="demo-ruleForm"
        style="width:80%"
      >
        <div class="text-header">管理系统</div>
        <el-form-item  prop="account">
          <el-input
            type="text"
            v-model="form.account"
            placeholder="登录名"
            autocomplete="off"
          >
          <template #prefix>
          <el-icon class="el-input__icon"><UserFilled /></el-icon>
        </template>
          </el-input>
        </el-form-item>
        <el-form-item  prop="pass">
          <el-input
            type="password"
            placeholder="密码"
            v-model="form.pass"
            autocomplete="off"
          >
           <template #prefix>
          <el-icon class="el-input__icon"><Lock /></el-icon>
        </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" 
          
          style="width:100%;font-size:16px" @click="submitForm()"
            >登录</el-button
          >
          <el-button @click="resetForm()" :suffix-icon="Lock"
          type="text" size="mini" class="btn-right">重置</el-button>
        </el-form-item>
        <div class="tips">账号：admin 密码：123456</div>
      </el-form>
    </div>
    </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { UserFilled, Lock } from '@element-plus/icons'
export default defineComponent({
    components: {
        UserFilled, Lock ,
    },
  setup() {
    const router = useRouter();
    const loginForm = ref(null);
    const form = reactive({
      account: "",
      pass: "",
    });
    const submitForm = () => {
      console.log(form);
      if (form.account != "admin" || form.pass != "123456") {
        ElMessage({
            message:"用户名或密码错误！",
            type:'error'
            });
        return;
      }else{
          ElMessage({
            message:"登录成功！",
            type:'success'
            });
           localStorage.setItem("user", JSON.stringify(form));
           router.push("/");
      }
     
    };
    const resetForm = () => {
      loginForm.value.resetFields();
    };
    return {
      form,
      submitForm,
      resetForm,
      loginForm,
    };
  },
});
</script>

<style scoped>
.text-header {
  text-align: center;
  font-size: 20px;
  color: #fff;
  margin-bottom: 50px;
}
.login {
  width: 100vw;
  height: 100vh;
  /* background-image: url("https://img.zcool.cn/community/011cc26067bf7c11013e87f41a7eb1.jpg@1280w_1l_2o_100sh.jpg"); */
  background-image: url("https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic14.nipic.com%2F20110527%2F7539498_003034652127_2.jpg&refer=http%3A%2F%2Fpic14.nipic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1639813852&t=70ef4ff598736f132fc7f4a8f4c52d3a");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  /* filter: blur(5px); */
}
.login-form {
  position: absolute;
  width: 400px;
  height: 360px;
  top: 30%;
  right:12% ;
  border-radius: 10px;
  box-shadow: 1px 1px 5px #333;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #072f68cc;
}
.btn-right{
    float: right;
    font-size: 15px;
    color: #3c8dbc;
}
.btn-right:hover{
    color: #8dc0dd;
}
.login-form :deep(.el-form-item__label) {
  color: #000;
  font-size: 18px;
}
.login-form :deep(.el-input--prefix .el-input__inner){
    background: #ffffff00;
    color: #ffffff;
    border: #c8c7c770 1px solid;
}
.login-form :deep(.el-icon svg){
    color: #ffffff;
    font-size: 18px;
    transform: translateY(10%);
}
.tips{
    color: #ffffff;
}
</style>
