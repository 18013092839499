<template>
  <router-link to="/"><img class="logo" src="../../assets/header.png" alt="" /></router-link>
  <div style="display: flex;align-items: center">
  <el-badge :value="$store.state.newsCount" :max="99" class="wrap-news">
    <el-button type="primary" plain icon="el-icon-message" @click="()=> $router.push('/Account/NewsList')"></el-button>
  </el-badge>
  <el-popover placement="bottom" trigger="click">
    <template #reference>
      <el-button class="hint-btn" icon="el-icon-question" type="primary" plain></el-button>
    </template>
    <div>
      <div class="item-box">
        <i style="cursor: pointer;"  @click="operate('1')" class="el-icon-tickets"></i>
        <span style="cursor: pointer;" @click="operate('1')" class="operate-box">操作手册</span>
      </div>
      <div class="item-box" style="margin-top: 7px;">
        <i style="cursor: pointer;"  @click="operate('2')" class="el-icon-video-play"></i>
        <span style="cursor: pointer;"  @click="operate('2')" class="operate-box">操作视频</span>
      </div>
    </div>
  </el-popover>
  <el-popover
    placement="bottom"
    :width="280"
    trigger="click"
    popper-class="wrap-pop"
  >
    <template #reference>
      <el-button type="primary" plain>{{ name }}</el-button>
    </template>
    <div class="wrap1">
      <p style=""><span class="wp">用户名：</span>{{ name }}</p>
      <p style="font-size: 12px">
        <span class="wp">登录名：</span>{{ username }}
      </p>
      <p style="font-size: 12px">
        <span class="wp">部门：</span>{{ departMentName }}
      </p>
    </div>
    <div class="wrap2">
      <el-button style="float: left" size="small" @click="accountSet"
        >账户设置</el-button
      >
      <el-button style="float: right" size="small" @click="loginOut"
        >退出</el-button
      >
    </div>
  </el-popover>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, getCurrentInstance } from "vue";
import mgr from '@/services/security.js'
export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance();
    const username = ref('')
    const name = ref('')
    const departMentName = ref('')
    // 退出登录
    const loginOut = () => {
      mgr.signoutRedirect();
    };
    const accountSet = () => {
      proxy.$router.push('/Account');
    };
    const getUserInfo =async () => {
      let me = proxy;
      await me.$store.dispatch('getUserInfo');
      me.departMentName = me.$store.state.userInfo.DepartmentName;
      let isDefaultPassword = me.$store.state.userInfo.isDefaultPassword;
      if(isDefaultPassword){
        proxy.$router.push('/Account/ChangePassword');
      }
    };
    const operate = (res) =>{
      if(res === '1'){
        //操作手册
        // let iframe = "<embed frameborder='0'  style='width: 100%;height: 100%' src='/aa.pdf#toolbar=0'></embed>"
        // let x = window.open()
        // x.document.open()
        // x.document.write(iframe)
        // x.document.title ='操作手册'
        // x.document.close()
        window.open(window.appConfig.apiUrls.handbookApi + '/操作手册.pdf')
      }else {
        //视频
       window.open(window.appConfig.apiUrls.handbookApi + '/演示视频.mp4')
      }
    };
    onMounted(async () => {
      const user = await mgr.getUser();
      proxy.$router.user=user;
      proxy.name = user.profile.name;
      proxy.username = user.profile.Username;
      proxy.getUserInfo();
    })
    return {
      name,
      username,
      departMentName,
      visible: ref(false),
      loginOut,
      accountSet,
      getUserInfo,
      operate
    };
  },
});
</script>


<style scoped>
p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

.wrap1 {
  text-align: center;
  background-color: #3c8dbc;
  color: rgba(255, 255, 255, 0.8);
  width: 100%;
  padding: 10px;
  padding-left: 0px;
  padding-right: 0px;
  height: 85px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.wrap1 p:first-child {
  font-size: 17px;
}
.wrap1 .wp {
  font-weight: bold;
}
.wrap2 {
  padding: 10px;
  overflow: hidden;
}
.wrap-news{
  margin-right: 20px;
}
.wrap-news .el-button{
  padding: 0px 10px;
  font-size: 24px;
  /* border: 0px; */
  min-height: 38px;
}
.hint-btn{
  padding: 0px 10px;
  font-size: 24px;
  /* border: 0px; */
  min-height: 38px;
}
.operate-box{
  padding-left: 10px;
}
.item-box:hover{
  color: #409eff;
}
</style>
