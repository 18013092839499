<template>
  <!--路由的出口-->
  <div id="wrap-router">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>
<script>
export default {
  name: "app-main.vue",
};
</script>
<style scoped>
#wrap-router {
  padding: 10px 10px 0 10px;
  height: calc(100% - 10px);
}
</style>
