<template>
    <div>
        <p>正在登录......</p>
    </div>
</template>

<script>
import mgr from '../services/security.js'

    export default {
        async created() {
            console.log('执行了callback');
            try {
                var result = await mgr.signinRedirectCallback();
                var returnToUrl= '/';
                if (result.state !== undefined) { returnToUrl = result.state;}
                this.$router.push({ path: returnToUrl });
            } catch (e) {
                this.$router.push({ name: 'Unauthorized' });
            }
        }
    }
</script>