import axios from "axios";
import mgr from '@/services/security.js'
import { ElMessageBox, ElMessage } from "element-plus";
import NProgress from 'nprogress'  
import 'nprogress/nprogress.css'  
axios.defaults.timeout = 20000;
axios.defaults.headers.post["Content-Type"] = "application/json";
// 拦截请求 请求头加上token
axios.interceptors.request.use(async (config) => {
    NProgress.start();  // 开启进度条
    const user = await mgr.getUser();
    // console.log('拦截器user', user);
    if (user) {
        const authToken = user.access_token;
        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        }
        return config;
    }
    mgr.signinRedirect();
    return config;
},
    (err) => {
        console.log('报错', err);
        //What do we do when we get errors?
    });

// 拦截响应
axios.interceptors.response.use((response) => {
    NProgress.done(); // 关闭进度条
    // console.log(response);
    return response;
}, error => {
    console.log('这是错误', error, error.response);
    if (error.response.status == 401) {
        ElMessageBox.confirm(
            '登录超期，请重新登录',
            {
                confirmButtonText: '确认',
                showClose: false,
                showCancelButton: false,
                type: 'warning',
            }).then(() => {
                ElMessage({
                    type: 'success',
                    message: '已确认',
                })
                mgr.signoutRedirect();
            })
    } else if (error.response.status == 403) {
        ElMessage.error({ message: '权限不足，请联系管理员' })
    } else if (error.response.status == 404) {
        ElMessage.error({ message: '请求地址为空' })
    } else if (error.response.status == 408) {
        ElMessage.error({ message: '请求超时' })
    } else if (error.response.status == 500) {
        ElMessage.error({ message: '服务器异常' })
    } else if (error.response.status == 504) {
        ElMessage.error({ message: '网关超时' })
    } else {
        if (error.response.data.message) {
            ElMessage.error({ message: error.response.data.message })
        } else {
            ElMessage.error({ message: `出现${error.response.status}错误` })
        }
    }
    return;
})
export default axios;
